import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Editable from '../editable';
import Submit from '../submit-button';
import TemplateContainer from '../template-container';
import MessageBody from '../message-body';
import MessageText from '../message-text';
import generator from './word-generator';

const MeMyselfAndI = ({
  className,
  content,
  messageValid,
  name,
  onSubmit,
  updateContent,
  updateName,
  viewOnly
}) => (
  <div className={ className }>
    <TemplateContainer viewOnly={ viewOnly }>
      <MessageText as="div">
        Dear&nbsp;
        <Editable
          disabled={ viewOnly }
          html={ name }
          tagName='span'
          placeholder='your name'
          onChange={ e => updateName(e.currentTarget.innerText) }
          tabIndex='1'
        />,
      </MessageText>
      <MessageBody viewOnly={ viewOnly }>
        Let's get&nbsp;
        <Editable
          disabled={ viewOnly }
          html={content[0] || ''}
          tagName='span'
          placeholder='physical tonight'
          onChange={ e => updateContent(0, e.currentTarget.innerText) }
          tabIndex='2'
        />.
      </MessageBody>
      <MessageText as="div">
        Your (self),
      </MessageText>
      <MessageText as="div">
        <Editable
          disabled={ viewOnly }
          html={ name }
          tagName='span'
          placeholder='Your name'
          onChange={ e => updateName(e.currentTarget.innerText) }
          tabIndex='3'
        />
      </MessageText>
    </TemplateContainer>
    <Submit
      disabled={ !messageValid }
      onClick={ onSubmit }
      visible={ !viewOnly }
    >
      Next
    </Submit>
  </div>
);

MeMyselfAndI.generateContent = () => {
  return [
    `${generator('adjective')} ${generator('time')}`,
    'subconscious admirer'
  ];
}

MeMyselfAndI.validateContent = content => {
  return content.length === 1
      && content.every(item => item.trim().length > 0);
}

MeMyselfAndI.emailSubject = () => {
  return `You sent yourself a message`;
}

MeMyselfAndI.emailMessage = "You've got a message from a very special friend (yourself).";

MeMyselfAndI.asText = (name, content) => {
  return `Dear ${name},\n\n` +
         `Let's get ${content[0]}.\n\n` +
         `Your (self),\n` +
         name;
}

MeMyselfAndI.id = 6;
MeMyselfAndI.relationship = 'Me, Myself, and I';
MeMyselfAndI.nameRequired = true;
MeMyselfAndI.senderRequired = false;

MeMyselfAndI.propTypes = {
  className: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  messageValid: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  updateContent: PropTypes.func.isRequired,
  updateName: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool.isRequired
}

export default styled(MeMyselfAndI)`
  color: hsl(0, 0%, 100%);
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  padding: ${({ viewOnly }) => viewOnly ? '72px 48px 84px' : '24px 16px' };
  text-align: ${({ viewOnly }) => viewOnly ? 'left' : 'center'};
`;

