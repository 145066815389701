import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Editable from '../editable';
import Submit from '../submit-button';
import TemplateContainer from '../template-container';
import MessageBody from '../message-body';
import MessageText from '../message-text';
import generator from './word-generator';

const CurrentFlame = ({
  className,
  content,
  messageValid,
  name,
  onSubmit,
  updateContent,
  updateName,
  viewOnly
}) => (
  <div className={ className }>
    <TemplateContainer viewOnly={ viewOnly }>
      <MessageText as="div">
        Dear&nbsp;
        <Editable
          disabled={ viewOnly }
          html={content[0] || ''}
          tagName='span'
          placeholder='current flame'
          onChange={ e => updateContent(0, e.currentTarget.innerText) }
          tabIndex='1'
        />,
      </MessageText>
      <MessageBody viewOnly={ viewOnly }>
        I&nbsp;
        <Editable
          disabled={ viewOnly }
          html={content[1] || ''}
          tagName='span'
          placeholder='love'
          onChange={ e => updateContent(1, e.currentTarget.innerText) }
          tabIndex='2'
        />
        &nbsp;the way you&nbsp;
        <Editable
          disabled={ viewOnly }
          html={content[2] || ''}
          tagName='span'
          placeholder='looked at me last night'
          onChange={ e => updateContent(2, e.currentTarget.innerText) }
          tabIndex='3'
        />
        .
      </MessageBody>
      <MessageText as="div">
        Your&nbsp;
        <Editable
          disabled={ viewOnly }
          html={content[3] || ''}
          tagName='span'
          placeholder='eternally burning'
          onChange={ e => updateContent(3, e.currentTarget.innerText) }
          tabIndex='4'
        />
        &nbsp;flame,
      </MessageText>
      <MessageText as="div">
        <Editable
          disabled={ viewOnly }
          html={ name }
          tagName='span'
          placeholder='Your name'
          onChange={ e => updateName(e.currentTarget.innerText) }
          tabIndex='5'
        />
      </MessageText>
    </TemplateContainer>
    <Submit
      disabled={ !messageValid }
      onClick={ onSubmit }
      visible={ !viewOnly }
    >
      Next
    </Submit>
  </div>
);

CurrentFlame.generateContent = () => {
  return [
    'current flame',
    generator('present'),
    `${generator('present')} ${generator('noun')} ${generator('time')}`,
    'eternally burning'
  ];
}

CurrentFlame.validateContent = content => {
  return content.length === 4
      && content.every(item => item.trim().length > 0);
}

CurrentFlame.emailSubject = name => {
  return `Your flame ${name} sent you a message`;
}

CurrentFlame.emailMessage = "You've got a message from an admirer.";

CurrentFlame.asText = (name, content) => {
  return `Dear ${content[0]},\n\n` +
         `I ${content[1]} the way you ${content[2]}.\n\n` +
         `Your ${content[3]} flame,\n` +
         name;
}

CurrentFlame.id = 5;
CurrentFlame.relationship = 'Current Flame';
CurrentFlame.nameRequired = true;
CurrentFlame.senderRequired = true;

CurrentFlame.propTypes = {
  className: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  messageValid: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  updateContent: PropTypes.func.isRequired,
  updateName: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool.isRequired
}

export default styled(CurrentFlame)`
  color: hsl(0, 0%, 100%);
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  padding: ${({ viewOnly }) => viewOnly ? '72px 48px 84px' : '24px 16px' };
  text-align: ${({ viewOnly }) => viewOnly ? 'left' : 'center'};
`;

