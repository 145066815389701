import styled from 'styled-components';
import Button from '../controls/button';

export default styled(Button)`
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  display: ${({ visible = true }) => visible ? 'block' : 'none'};
  margin: 0 auto;
  max-width: 256px;
  opacity: ${({ disabled }) => disabled ? '0.5' : '1'};
  transition: opacity 200ms ease-out;
  width: 100%;
`;
