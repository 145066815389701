import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import postmark from '../../images/postmark.svg';

const contentBackground = ({ cardColor, viewOnly }) => {
  if (cardColor && viewOnly) {
    return `${cardColor} url(${ postmark })`;
  }

  if (cardColor) return cardColor;

  return 'hsl(0, 2%, 54%)';
}

const Container = styled.div`
  background-image: repeating-linear-gradient(
            135deg,
            #fff,
            #fff 20px,
            ${props => props.cardColor || 'hsl(0, 2%, 54%)'} 20px,
            ${props => props.cardColor || 'hsl(0, 2%, 54%)'} 40px);
  border-radius: 4px 4px 0 0;
  margin: ${({ viewOnly }) => viewOnly ? '0 auto 16px' : '0 auto'};
  max-width: 640px;
  padding: ${({ viewOnly }) => viewOnly ? '16px' : '16px 16px 0' };
  width: 80vw;

  @media screen and (min-height: 1024px) and (min-width: 700px) {
    & {
      margin-bottom: 24px;
      padding-bottom: 16px;
    }
  }
`;

const Content = styled.div`
  background: ${contentBackground};
  background-repeat: no-repeat;
  background-size: 20% auto;
  background-position: right 36px top 48px;
  min-height: calc(6 * 24px);
`

const Card = (props) => (
  <Container cardColor={props.cardColor} {...props}>
    <Content cardColor={props.cardColor} viewOnly={ props.viewOnly }>
      { props.children }
    </Content>
  </Container>
)

Card.propTypes = {
  cardColor: PropTypes.string.isRequired,
  viewOnly: PropTypes.bool
};

export default Card;
