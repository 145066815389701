import PlatonicChum from './platonic-chum';
import FriendsWithBennies from './friends-with-bennies';
import SecretAdmirer from './secret-admirer';
import FutureLover from './future-lover';
import CurrentFlame from './current-flame';
import MeMyselfAndI from './me-myself-and-i';

export default [
  PlatonicChum,
  FriendsWithBennies,
  SecretAdmirer,
  FutureLover,
  CurrentFlame,
  MeMyselfAndI
]
