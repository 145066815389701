import styled from 'styled-components';
import ContentEditable from 'react-contenteditable';

export default styled(ContentEditable)`
  border-bottom: 1px solid hsl(0, 0%, 100%);
  display: inline;
  font-weight: ${({disabled}) => disabled ? '500' : '400'};
  outline: 0;
  overflow-wrap: break-word;
  padding: 0 2px;
  position: relative;
  text-align: left;
  vertical-align: baseline;

  :empty {
    display: inline-block;
    line-height: 19px;
  }

  @media (min-width: 768px) {
    border-bottom-width: 2px;

    :empty {
      line-height: 30px;
    }
  }

  :empty::before {
    color: hsla(0, 0%, 100%, 0.5);
    content: "${props => props.placeholder}";
  }
`;
