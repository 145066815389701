import styled from 'styled-components';

export default styled.div`
  margin: 0 0 24px;
  max-width: ${({ viewOnly }) => viewOnly ? '320px' : 'auto'};
  min-height: calc(4 * 26px);
  padding: 24px 0;

  @media (min-width: 768px) {
    min-height: calc(4 * 42px);
  }
`;
