import React from 'react';
import styled from 'styled-components';

import VisuallyHidden from './visually-hidden';
import LogoSVG from '../images/inline-svg/logo.svg';

const Container = styled.header`
  align-items: center;
  background: hsl(0, 0%, 100%);
  color: hsl(76, 13%, 16%);
  display: flex;
  justify-content: space-between;
  margin: 0 0 50px;
  padding: 15px 7vw;

  @media (min-width: 768px) {
    background: transparent;
    color: hsl(0, 0%, 100%);
    margin: 0;
    padding: 55px 7vw;
  }
`;

const Title = styled.h1`
  margin: 0;
  padding: 0;
`;

const Logo = styled(LogoSVG)`
  color: hsl(76, 13%, 16%);
  width: 90px;

  @media (min-width: 768px) {
    color: hsl(0, 0%, 100%);
    width: 134px;
  }
`;

export default ({ nav, themeName }) => (
  <Container>
    <Title>
      <a href="https://getmaude.com" style={{ border: 'none' }}>
        <Logo theme={ themeName } />
      </a>
      <VisuallyHidden>
        Maude
      </VisuallyHidden>
    </Title>
    { nav }
  </Container>
);
