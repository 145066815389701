import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Editable from '../editable';
import Submit from '../submit-button';
import TemplateContainer from '../template-container';
import MessageBody from '../message-body';
import MessageText from '../message-text';
import generator from './word-generator';

const FutureLover = ({
  className,
  content,
  messageValid,
  name,
  onSubmit,
  updateContent,
  updateName,
  viewOnly
}) => (
  <div className={ className }>
    <TemplateContainer viewOnly={ viewOnly }>
      <MessageText as="div">
        Dear&nbsp;
        <Editable
          disabled={ viewOnly }
          html={content[0] || ''}
          tagName='span'
          placeholder='future lover'
          onChange={ e => updateContent(0, e.currentTarget.innerText) }
          tabIndex='1'
        />,
      </MessageText>
      <MessageBody viewOnly={ viewOnly }>
        <Editable
          disabled={ viewOnly }
          html={content[1] || ''}
          tagName='span'
          placeholder='Wild'
          onChange={ e => updateContent(1, e.currentTarget.innerText) }
          tabIndex='2'
        />
        &nbsp;thing, you make my&nbsp;
        <Editable
          disabled={ viewOnly }
          html={content[2] || ''}
          tagName='span'
          placeholder='heart sing'
          onChange={ e => updateContent(2, e.currentTarget.innerText) }
          tabIndex='3'
        />
        .
      </MessageBody>
      <MessageText as="div">
        Your&nbsp;
        <Editable
          disabled={ viewOnly }
          html={content[3] || ''}
          tagName='span'
          placeholder='devoted'
          onChange={ e => updateContent(3, e.currentTarget.innerText) }
          tabIndex='4'
        />
        &nbsp;friend,
      </MessageText>
      <MessageText as="div">
        <Editable
          disabled={ viewOnly }
          html={ name }
          tagName='span'
          placeholder='Your name'
          onChange={ e => updateName(e.currentTarget.innerText) }
          tabIndex='5'
        />
      </MessageText>
    </TemplateContainer>
    <Submit
      disabled={ !messageValid }
      onClick={ onSubmit }
      visible={ !viewOnly }
    >
      Next
    </Submit>
  </div>
);

FutureLover.generateContent = () => {
  return [
    'future lover',
    generator('adjective'),
    `${generator('noun')} ${generator('present')}`,
    'devoted'
  ];
}

FutureLover.validateContent = content => {
  return content.length === 4
      && content.every(item => item.trim().length > 0);
}

FutureLover.id = 4;
FutureLover.relationship = 'Future Lover';
FutureLover.nameRequired = true;
FutureLover.senderRequired = true;

FutureLover.emailSubject = name => {
  return `Your admirer ${name} sent you a message`;
}

FutureLover.emailMessage = "You've got a message from a friend.";

FutureLover.asText = (name, content) => {
  return `Dear ${content[0]},\n\n` +
         `${content[1]} thing, you make my ${content[2]}.\n\n` +
         `Your ${content[3]} friend,\n` +
         name;
}

FutureLover.propTypes = {
  className: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  messageValid: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  updateName: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool.isRequired
}

export default styled(FutureLover)`
  color: hsl(0, 0%, 100%);
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  padding: ${({ viewOnly }) => viewOnly ? '72px 48px' : '24px 16px' };
  text-align: ${({ viewOnly }) => viewOnly ? 'left' : 'center'};
`;
