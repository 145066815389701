export default [
  {
    id: 1,
    name: 'iffy shade of gray',
    value: 'hsl(0, 2%, 58%)'
  },
  {
    id: 2,
    name: 'a better morning',
    value: 'hsl(41, 75%, 40%)'
  },
  {
    id: 3,
    name: '@!#flix',
    value: 'hsl(18, 71%, 46%)'
  },
  {
    id: 4,
    name: 'and chill',
    value: 'hsl(223, 40%, 39%)'
  },
  {
    id: 5,
    name: 'wine mouth',
    value: 'hsl(354, 17%, 65%)'
  },
  {
    id: 6,
    name: 'bare bottom',
    value: 'hsl(31, 35%, 56%)'
  },
  {
    id: 7,
    name: 'green with envy',
    value: 'hsl(85, 10%, 51%)'
  },
  {
    id: 8,
    name: 'feeling toasty',
    value: 'hsl(26, 44%, 40%)'
  },
];

