import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Masthead from './masthead';

import '../styles/base.css';

const Container = styled.div`
  background-color: ${props => props.themeName === 'dark' ? 'hsl(76, 13%, 16%)' : 'hsl(350, 19%, 94%)'};
  display: ${props => props.flex ? 'flex' : 'block'};
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  width: 100%;
`;

const Layout = (props) => (
  <Container {...props}>
    <Masthead {...props} />
    {props.children}
  </Container>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.string,
  flex: PropTypes.bool
}

export default Layout
