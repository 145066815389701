import styled from 'styled-components';

import Body from '../type/body';

export default styled(Body)`
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 42px;
  }
`;
