const presentVerbs = [
  'bed',
  'bone',
  'canoodle',
  'caress',
  'crush',
  'cup',
  'dance',
  'discipline',
  'drunk dial',
  'eat',
  'explore',
  'fall for',
  'get down',
  'go out with',
  'grind',
  'hold',
  'hold on to',
  'hook-up',
  'hug',
  'indulge',
  'kiss',
  'laugh',
  'lay with',
  'lick',
  'love',
  'make love',
  'massage',
  'nibble',
  'numb',
  'nuzzle',
  'obsess',
  'pinch',
  'pinch',
  'please',
  'quiver',
  'ravage',
  'savor',
  'sext',
  'shag',
  'smooch',
  'snog',
  'soothe',
  'spoon',
  'squeeze',
  'stroke',
  'tantalize',
  'taste',
  'tease',
  'tickle',
  'tug',
  'undulate',
  'wiggle'
];

const gerundVerbs = [
  'bedding',
  'boning',
  'canoodling',
  'caressing',
  'crushing',
  'cupping',
  'dancing',
  'disciplining',
  'drunk dialing',
  'eating',
  'exploring',
  'falling for',
  'getting down',
  'going out with',
  'grinding',
  'holding',
  'holding on to',
  'hooking-up',
  'hugging',
  'indulging',
  'kissing',
  'laughing',
  'laying with',
  'licking',
  'loving',
  'make love',
  'massaging',
  'nibbling',
  'numbing',
  'nuzzling',
  'obsessing',
  'pinching',
  'pinching',
  'pleasing',
  'quivering',
  'ravaging',
  'savoring',
  'sexting',
  'shagging',
  'smooching',
  'snogging',
  'soothing',
  'spooning',
  'squeezing',
  'stroking',
  'tantalizing',
  'tasting',
  'teasing',
  'tickling',
  'tugging',
  'undulating',
  'wiggling'
];

const pastParticipleVerbs = [
  'amazed',
  'aroused',
  'bewitched',
  'blessed',
  'caressed',
  'charmed',
  'crushed',
  'enchanted',
  'excited',
  'in love with',
  'loved',
  'obsessed',
  'pleased',
  'romanced',
  'seduced',
  'smitten',
  'soothed',
  'stoked',
  'stunned',
  'taken',
  'tantalized',
  'thankful',
  'thrilled',
  'thrilled',
  'tickled',
  'touched'
];

const nouns = [
  'admirer',
  'affection',
  'affection',
  'afternoon delight',
  'allure',
  'back',
  'beauty',
  'bed',
  'bits',
  'booty',
  'booty call',
  'caress',
  'cuddle',
  'dalliance',
  'delight',
  'devotion',
  'dingle berry',
  'ecstasy',
  'elegance',
  'embrace',
  'enchantress',
  'favor',
  'feather',
  'flame',
  'fling',
  'fondness',
  'friend with benefits',
  'gentleness',
  'goal',
  'handcuffs',
  'heartstrings',
  'inner beauty',
  'intimacy',
  'laugh',
  'legs',
  'life',
  'love',
  'love affair',
  'lover',
  'loyalty',
  'medication',
  'obsession',
  'passion',
  'pep talk',
  'pleasure',
  'relationship',
  'soft spot',
  'softness',
  'tenderness',
  'toy',
  'urge',
  'vibe',
  'warmth',
  'wingman',
  'wingwoman',
  'zest'
];

const adjectives = [
  'alluring',
  'amazing',
  'ample',
  'arousing',
  'attractive',
  'best',
  'bewitching ',
  'bootylicious',
  'breathtaking',
  'charming',
  'curvy',
  'delicious',
  'dirty',
  'easy',
  'enchanting',
  'enthusiastic',
  'erotic',
  'exciting ',
  'fair',
  'flirty',
  'frisky',
  'gorgeous',
  'handsome',
  'hot',
  'intense',
  'inviting',
  'kinky',
  'lovely',
  'luscious',
  'lusty',
  'mischievous',
  'naked',
  'naughty',
  'passionate',
  'pleasing',
  'racy',
  'radiant',
  'romantic',
  'saucy',
  'scorching',
  'seductive',
  'sexy',
  'softly',
  'stiff',
  'stunning',
  'sweet',
  'vivacious',
  'voluptuous',
  'wild',
  'zesty'
];

const times = [
  'at 4am',
  'this afternoon',
  'today',
  'tomorrow morning',
  'tonight'
]

const articles = [
  'a',
  'an',
  'the'
]

function selectRandomWord(list) {
  const index = Math.floor(Math.random() * list.length);
  return list[index];
}

export default (type) => {
  switch(type) {
    case 'present':
      return selectRandomWord(presentVerbs);
    case 'gerund':
      return selectRandomWord(gerundVerbs);
    case 'past-participle':
      return selectRandomWord(pastParticipleVerbs);
    case 'noun':
      return selectRandomWord(nouns);
    case 'adjective':
      return selectRandomWord(adjectives);
    case 'time':
      return selectRandomWord(times);
    case 'article':
      return selectRandomWord(articles);
    default:
      throw new Error('Unknown word type');
  };
};
