import { atobUTF8, btoaUTF8 } from '../../util/utf8-base64-encoder';

export const encode = (senderName, colorID, templateID, content) => {
  return btoaUTF8([colorID, templateID, senderName, content.join("⟪")].join("⟨"));
}

export const decode = (encoded) => {
  let [colorID, templateID, senderName, content] = atobUTF8(encoded).split("⟨");

  content = content.split("⟪");
  return [colorID, templateID, senderName, content];
}

export const send = async (recipient, sender, senderName, colorID, templateID, content, includeInContest) => {
  const payload = encode(senderName, colorID, templateID, content);
  const viewUrl = window.location.origin + "/message/" + payload;
  const origin = process.env.NODE_ENV === 'production' ? window.location.origin : 'http://localhost:9000';
  const basePath = process.env.NODE_ENV === 'production' ? '/.netlify/functions/' : '/';

  await fetch(`${origin}${basePath}send-love-message`, {
    body: JSON.stringify({
      includeInContest,
      recipient,
      sender,
      senderName,
      viewUrl
    }),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'post'
  });
}
