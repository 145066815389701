import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Editable from '../editable';
import Submit from '../submit-button';
import TemplateContainer from '../template-container';
import MessageBody from '../message-body';
import MessageText from '../message-text';
import generator from './word-generator';

const FriendsWithBennies = ({
  className,
  content,
  messageValid,
  name,
  onSubmit,
  updateContent,
  updateName,
  viewOnly
}) => (
  <div className={ className }>
    <TemplateContainer viewOnly={ viewOnly }>
      <MessageText as="div">
        Dear&nbsp;
        <Editable
          disabled={ viewOnly }
          html={content[0] || ''}
          tagName='span'
          placeholder='special friend'
          onChange={ e => updateContent(0, e.currentTarget.innerText) }
          tabIndex='1'
        />,
      </MessageText>
      <MessageBody viewOnly={ viewOnly }>
        Can you&nbsp;
        <Editable
          disabled={ viewOnly }
          html={content[1] || ''}
          tagName='span'
          placeholder='feel'
          onChange={ e => updateContent(1, e.currentTarget.innerText) }
          tabIndex='2'
        />
        &nbsp;the&nbsp;
        <Editable
          disabled={ viewOnly }
          html={content[2] || ''}
          tagName='span'
          placeholder='love'
          onChange={ e => updateContent(2, e.currentTarget.innerText) }
          tabIndex='3'
        />
        ?
      </MessageBody>
      <MessageText as="div">
        Your&nbsp;
        <Editable
          disabled={ viewOnly }
          html={content[3] || ''}
          tagName='span'
          placeholder='equally special'
          onChange={ e => updateContent(3, e.currentTarget.innerText) }
          tabIndex='4'
        />
        &nbsp;friend,
      </MessageText>
      <MessageText as="div">
        <Editable
          disabled={ viewOnly }
          html={ name }
          tagName='span'
          placeholder='Your name'
          onChange={ e => updateName(e.currentTarget.innerText) }
          tabIndex='5'
        />
      </MessageText>
    </TemplateContainer>
    <Submit
      disabled={ !messageValid }
      onClick={ onSubmit }
      visible={ !viewOnly }
    >
      Next
    </Submit>
  </div>
);

FriendsWithBennies.generateContent = () => {
  return [
    'special friend',
    generator('present'),
    `${generator('noun')} ${generator('time')}`,
    'equally special'
  ];
}

FriendsWithBennies.validateContent = content => {
  return content.length === 4
      && content.every(item => item.trim().length > 0);
}

FriendsWithBennies.emailSubject = name => {
  return `Your friend ${name} sent you a message`;
}

FriendsWithBennies.emailMessage = "You've got a message from a special friend.";

FriendsWithBennies.asText = (name, content) => {
  return `Dear ${content[0]},\n\n` +
         `Can you ${content[1]} the ${content[2]}?\n\n` +
         `Your ${content[3]} friend,\n` +
         name;
}

FriendsWithBennies.id = 2;
FriendsWithBennies.relationship = 'Friends with Bennies';
FriendsWithBennies.nameRequired = true;
FriendsWithBennies.senderRequired = true;

FriendsWithBennies.propTypes = {
  className: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  messageValid: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  updateContent: PropTypes.func.isRequired,
  updateName: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool.isRequired
}

export default styled(FriendsWithBennies)`
  color: hsl(0, 0%, 100%);
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  padding: ${({ viewOnly }) => viewOnly ? '72px 48px 84px' : '24px 16px' };
  text-align: ${({ viewOnly }) => viewOnly ? 'left' : 'center'};
`;
