import styled from 'styled-components';

export default styled.p`
  color: hsl(0, 0%, 100%);
  font-family: 'GT America', 'Helvetica Neue', Helvetica, Arial, Tahoma, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 21px;
  margin: 0;
  padding: 0;
`;
