import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Editable from '../editable';
import Submit from '../submit-button';
import TemplateContainer from '../template-container';
import MessageBody from '../message-body';
import MessageText from '../message-text';
import generator from './word-generator';

class SecretAdmirer extends React.Component {

  componentDidMount() {
    this.props.updateName("");
  }

  render() {
    const {
      className,
      content,
      messageValid,
      onSubmit,
      updateContent,
      viewOnly
    } = this.props;

    return (
      <div className={ className }>
        <TemplateContainer viewOnly={ viewOnly }>
          <MessageText as="div">
            Dear&nbsp;
            <Editable
              disabled={ viewOnly }
              html={content[0] || ''}
              tagName='span'
              placeholder='secret crush'
              onChange={ e => updateContent(0, e.currentTarget.innerText) }
              tabIndex='1'
            />,
          </MessageText>
          <MessageBody viewOnly={ viewOnly }>
            I'm so&nbsp;
            <Editable
              disabled={ viewOnly }
              html={content[1] || ''}
              tagName='span'
              placeholder='smitten'
              onChange={ e => updateContent(1, e.currentTarget.innerText) }
              tabIndex='2'
            />
            &nbsp;with your&nbsp;
            <Editable
              disabled={ viewOnly }
              html={content[2] || ''}
              tagName='span'
              placeholder='smile'
              onChange={ e => updateContent(2, e.currentTarget.innerText) }
              tabIndex='3'
            />
            .
          </MessageBody>
          <MessageText as="div">
            Your&nbsp;
            <Editable
              disabled={ viewOnly }
              html={content[3] || ''}
              tagName='span'
              placeholder='super secret admirer'
              onChange={ e => updateContent(3, e.currentTarget.innerText) }
              tabIndex='3'
            />
          </MessageText>
        </TemplateContainer>
        <Submit
          disabled={ !messageValid }
          onClick={ onSubmit }
          visible={ !viewOnly }
        >
          Next
        </Submit>
      </div>
    );
  }
}

SecretAdmirer.generateContent = () => {
  return [
    'secret crush',
    generator('past-participle'),
    generator('noun'),
    'not at all creepy'
  ];
}

SecretAdmirer.validateContent = content => {
  return content.length === 4
      && content.every(item => item.trim().length > 0);
}

SecretAdmirer.emailSubject = () => {
  return `A secret admirer sent you a message`;
}

SecretAdmirer.emailMessage = "You've got a message from a secret admirer.";

SecretAdmirer.asText = (name, content) => {
  return `Dear ${content[0]},\n\n` +
         `I'm so ${content[1]} with your ${content[2]}.\n\n` +
         `Your ${content[3]}`
}

SecretAdmirer.id = 3;
SecretAdmirer.relationship = 'Secret Admirer';
SecretAdmirer.nameRequired = false;
SecretAdmirer.senderRequired = true;

SecretAdmirer.propTypes = {
  className: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  messageValid: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  updateName: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool.isRequired
}

export default styled(SecretAdmirer)`
  color: hsl(0, 0%, 100%);
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  padding: ${({ viewOnly }) => viewOnly ? '72px 48px 84px' : '24px 16px' };
  text-align: ${({ viewOnly }) => viewOnly ? 'left' : 'center'};
`;
