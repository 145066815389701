import styled from 'styled-components';

export const Button = styled.button`
  background: hsl(0, 0%, 100%);
  border: 1px solid transparent;
  border-radius: 2px;
  box-sizing: border-box;
  color: hsl(0, 0%, 28%);
  cursor: pointer;
  display: inline-block;
  font-family: 'Calibre', 'Helvetica Neue', Helvetica, Arial, Tahoma, sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 42px;
  outline: 0;
  padding: 0 18px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
`;

export const PlainButton = styled(Button)`
  background: transparent;
`;

export const OutlineButton = styled(Button)`
  background: transparent;
  border-color: hsl(0, 0%, 100%);
`;

export default Button;
